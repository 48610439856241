import { useEffect, useState } from "react";
import Image from "next/image";
import NextLink from "next/link";
import { getClient } from "lib/sanity.server";
import { GET_REVIEWS, GET_REVIEWS_BY_TYPE } from "data/index";
import CustomLink from "components/link";
import Picture from "components/picture";
import StarRating from "components/star-rating";
import SnapSlider from "components/snap-slider";
import logoReview from "../images/logos/logoReview.png";

const ReviewSlider = ({ data = {} }) => {
  const { title, reviewType } = data;
  const [priority, setPriority] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getClient(false)
      .fetch(!!reviewType ? GET_REVIEWS_BY_TYPE : GET_REVIEWS, {
        reviewType: reviewType || "",
      })
      .then((result) => {
        setReviews(result.reviews ?? []);
        setTimeout(() => {
          setPriority(true);
        }, 2000);
      });
  }, []);

  return (
    <div className="theme theme--softgradient bg-[#F0F2EE] py-20 lg:py-20">
      <div className="mx-auto">
        <div className="flex w-full flex-col items-center px-10 md:px-6">
          <h2 className="mb-4 max-w-[780px] pb-4 text-center text-4xl font-normal text-accent-900 lg:text-5xl">
            {title}
          </h2>
          <Image src={logoReview} width={250} height={130} alt="Build Appeal Logos" />
          <CustomLink
            className="!mt-10 text-sm font-medium text-neutral-800"
            title="View Customer Reviews"
            url="/reviews"
            shouldScroll
            isButton
            styles={{ isLarge: true }}
          />
        </div>
        <div className="mt-7 mb-5 w-full lg:mt-16 ">
          <SnapSlider>
            {reviews?.map((review) => {
              return (
                <NextLink
                  key={review._id}
                  href={`/gallery/project/${review.project?._id}/photos?scroll_to_photo_id=${review.featuredImage?._id}&back=home`}
                >
                  <div className="mb-5 px-1.5 lg:mb-10 lg:cursor-pointer">
                    <div
                      className={
                        "flex h-full min-h-[400px] w-[100vw] flex-col rounded-xl bg-neutral-100 pb-5 sm:w-[50vw] lg:min-h-[505px] lg:w-[33vw] lg:pb-9 xl:w-[25vw] 2xl:w-[20vw]"
                      }
                    >
                      {review?.featuredImage && (
                        <Picture
                          width={400}
                          height={250}
                          objectFit="cover"
                          layout="intrinsic"
                          priority={priority}
                          picture={review.featuredImage}
                          className="rounded-t-xl"
                          alt={review.title}
                        />
                      )}
                      <div className="flex flex-1 flex-col justify-between px-5">
                        <div>
                          <div className="mt-6 flex items-start justify-between">
                            <div className="text-lg font-medium text-neutral-900">{review?.clientName || ""}</div>
                            <div className="flex-shrink-0 -translate-y-1">
                              <StarRating rating={review?.rating || 5} size={13} />
                            </div>
                          </div>
                          {review?.project && (
                            <div className="flex content-center items-center pt-1.5">
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.95832 4.60002C9.66666 1.91669 7.27499 -0.124979 4.53332 0.166687C1.79166 0.458354 -0.191676 2.85002 0.099991 5.59169C0.216658 6.75835 0.741658 7.80835 1.55832 8.62502L4.64999 11.7167C4.88332 11.95 5.23332 11.95 5.46666 11.7167L8.55832 8.62502C9.54999 7.57502 10.075 6.11669 9.95832 4.60002ZM4.99999 7.75002C3.54166 7.75002 2.37499 6.58335 2.37499 5.12502C2.37499 3.66669 3.54166 2.50002 4.99999 2.50002C6.45832 2.50002 7.62499 3.66669 7.62499 5.12502C7.62499 6.52502 6.45832 7.75002 4.99999 7.75002Z"
                                  fill="#252524"
                                />
                              </svg>

                              <span className="px-2.5 text-sm text-accent-900">
                                {review.project?.address?.city}, {review.project?.address?.state}
                              </span>
                            </div>
                          )}

                          <p className="mt-4 mb-4 min-h-[96px] overflow-y-hidden text-xs text-neutral-700">
                            {review?.quote || review?.title || ""}
                          </p>
                        </div>
                        <span className="self-start rounded-full bg-[#F0F2EE] px-3 pt-1 pb-1.5 text-xs text-accent-700">
                          Verified
                        </span>
                      </div>
                    </div>
                  </div>
                </NextLink>
              );
            })}
          </SnapSlider>
        </div>
      </div>
    </div>
  );
};

export default ReviewSlider;
