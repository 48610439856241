import { UimStar as StarIcon } from "@iconscout/react-unicons-monochrome";

const StarRating = ({ rating, size = 16 }) => {
  const num = parseInt(rating, 10);
  if (isNaN(num) || num <= 0) return null;
  return (
    <div className="text-star">
      {Array.from(Array(num).keys()).map((i) => (
        <StarIcon key={i} size={size} />
      ))}
    </div>
  );
};

export default StarRating;
